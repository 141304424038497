/* global IMAGES_ICON_ENDPOINT:true */
import store from '../../store'
import { chevron } from '../../icons'
import gridMin from '../../styles/vendors/grids-min.css'
import gridResp from '../../styles/vendors/grids-responsive-min.css'

const grid = { ...gridMin, ...gridResp }

function mungeWeatherData (data, size, showToday) {
  size = size || 'pure-u-lg-1-3'
  showToday = showToday || false

  let today = store.today()
  let tomorrow = store.tomorrow()
  let dayAftertomorrow = store.dayAfterTomorrow()

  let todayWeather = data.DailyForecasts.find(x => {
    let d = new Date(x.Date).getTime()
    return d >= today.getTime() && d <= tomorrow.getTime()
  })

  let tomorrowWeather = data.DailyForecasts.find(x => {
    let d = new Date(x.Date).getTime()
    return d >= tomorrow.getTime() && d <= dayAftertomorrow.getTime()
  })

  // determine the background image
  let backgroundImg = data.Photos[store.getRandomInt(0, data.Photos.length - 1)].PortraitLink.replace('L.jpg', 'M.jpg').replace('http:', 'https:').replace(/ /g, '_')

  // only do this for PDF to pre-load the background image...
  if (store.config.events && store.config.events.finishedBackgroundImage) {
    let body = document.getElementsByTagName('body')[0]
    let imgEl = document.createElement('img')
    imgEl.style.display = 'none'
    imgEl.onload = function () {
      imgEl.remove()
      store.config.events.finishedBackgroundImage()
    }
    imgEl.src = backgroundImg

    body.appendChild(imgEl)
  }

  let gridSize = grid['pure-u-1'] + ' ' + grid[size]
  if (store.config.disableResponsivenness) {
    gridSize = grid[size.replace('-sm', '').replace('-md', '').replace('-lg', '')]
  }

  const isSmallWidgetVar2 = store.widgetType === 'sm_sticker@2'
  const isMediumWidgetVar1 = store.widgetType === 'med_sticker@1'

  let weatherData = {
    showToday: showToday,
    gridSize: '"' + gridSize + '"',
    roundTemp: store.roundTempeture,
    isSmallWidgetVar2,
    isMediumWidgetVar1,
    displayWeatherText: !isSmallWidgetVar2 && !isMediumWidgetVar1,
    location: {
      name: store.location.name
    },
    backgroundImg: backgroundImg,
    weatherLink: data.Link,
    chevron: chevron(null, 'margin-bottom: 4px; max-height: 6px'),
    hiLabel: store.translations.hi,
    loLabel: store.translations.lo,
    current: {
      label: store.translations.now,
      temp: {
        value: data.Temperature[store.config.metric === 'C' ? 'Metric' : 'Imperial'].Value
      },
      weatherText: data.WeatherText,
      icon: {
        url: `${IMAGES_ICON_ENDPOINT}/weathericons/${('0' + data.WeatherIcon).slice(-2)}.svg`
      }
    },
    tonight: {
      label: store.isItEvening() ? store.translations.overnight : store.translations.tonight,
      weatherText: todayWeather.Night.IconPhrase,
      temp: {
        value: todayWeather.Temperature.Minimum.Value
      },
      icon: {
        url: `${IMAGES_ICON_ENDPOINT}/weathericons/${('0' + todayWeather.Night.Icon).slice(-2)}.svg`
      }
    },
    tomorrow: {
      label: store.translations.tomorrow,
      weatherText: tomorrowWeather.Night.IconPhrase,
      temp: {
        value: tomorrowWeather.Temperature.Maximum.Value
      },
      icon: {
        url: `${IMAGES_ICON_ENDPOINT}/weathericons/${('0' + tomorrowWeather.Night.Icon).slice(-2)}.svg`
      }
    }
  }

  // add today's widget to weather data
  if (showToday) {
    weatherData.today = {
      label: store.translations.today,
      weatherText: todayWeather.Day.IconPhrase,
      temp: {
        value: todayWeather.Temperature.Maximum.Value
      },
      icon: {
        url: `${IMAGES_ICON_ENDPOINT}/weathericons/${('0' + todayWeather.Day.Icon).slice(-2)}.svg`
      }
    }
  }

  return weatherData
}

export {
  mungeWeatherData
}

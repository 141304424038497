/* global API_ENDPOINT:true */
import api from './api'
import store from './store'
import log from './log'
import 'custom-event-polyfill'

let currentWeatherPID = null

// eslint-disable-next-line no-undef
var event = new CustomEvent('tempUnitChange')

// eslint-disable-next-line no-undef
var locationEvent = new CustomEvent('locationChange')

export default {
  rootEl: null,
  widgetType: null,
  config: {},
  location: {
    key: null,
    name: null,
    admin: null,
    country: null,
    countryId: null,
    gmtOffset: null
  },
  host: API_ENDPOINT.replace('api/proxy', ''),
  today (offset) {
    offset = offset || null
    let today = new Date()
    if (offset) {
      today.setDate(today.getDate() + offset)
    }
    today.setHours(0, 0, 0, 0) // remove time from date
    return today
  },
  tomorrow () {
    return this.today(1)
  },
  dayAfterTomorrow () {
    return this.today(2)
  },
  shifts: {},
  timeShift (hour, minute, second, cb) {
    log.debug(`Set timeshift for ${hour}:${minute}:${second}`)
    let shiftId = setTimeout(() => {
      this.timeShift(hour, minute, second, cb) // reset timeshift after called
      cb() // callback
    }, getTimeshiftTime(hour, minute, second))

    let key = `${hour}${minute}${second}`
    if (this.shifts.hasOwnProperty(key)) {
      clearTimeout(this.shifts[key])
    }
    this.shifts[key] = shiftId
  },
  clearAllShifts () {
    for (let key in this.shifts) {
      let val = this.shifts[key]
      clearTimeout(val)
    }
  },
  isItEvening () { // past 7PM but before 12AM
    let now = new Date()
    let hour = 19 // 7 PM // TODO: move to config

    // get specific time in milliseconds
    let ms = new Date(now.getFullYear(), now.getMonth(), now.getDate(), hour, 0, 0, 0)
    let msDiff = ms - now

    return msDiff < 0
  },
  isMobile () {
    // https://stackoverflow.com/a/3540295

    /* eslint-disable */
    return /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))
  },
  fetchAndInjectWeather (inject, cb) {
    cb = cb || null

    if (!inject || typeof inject !== 'function') {
      log.error('MISSING INJECT FUNCTION')
      return null
    }

    // clear existing intervals
    if (currentWeatherPID) {
      clearInterval(currentWeatherPID)
    }

    function fetch () {
      let data = {}
      api.currentConditions((conditions, err) => {
        if (err) {
          log.error('Failed to find weather')
        }
        api.fiveDayForecast((forecast, innerErr) => {
          data = Object.assign(forecast, conditions[0])
          inject(store.rootEl, data, store.config.theme, store.config.linkTarget)
          store.bindPartnerCode(store.rootEl)
          if (cb) {
            cb()
          }
        })
      })
    }

    let ms = this.config.weatherInterval * 60000
    log.debug(`Update Weather interval: ${ms} ms`)

    // checks for updates
    let time = setInterval(() => {

      // check the state of JW player
      let jwState = jwplayer('playerContainer').getState();

      log.debug(`Current JWPlayer state: ${jwState}`)
      if (jwState !== 'playing') {
        fetch()
      } else {
        log.debug('video is playing, skipping refresh')
      }
    }, ms)

    currentWeatherPID = time

    fetch() // call local fetch to do first call
  },
  hourlyTask (func, minute) {
    minute = minute || 0
    let pid = null
    let firstRun = true

    function task () {
      func()
      if (firstRun) {
        clearInterval(pid)
        firstRun = false
        pid = setInterval(task, 1000 * 60 * 60) // 1 hour
      }
    }

    // time until next hour
    let now = new Date()
    let nextHour = new Date()
    if (nextHour.getMinutes() >= minute) {
      nextHour.setHours(nextHour.getHours() + 1)
    }
    nextHour.setMinutes(minute)
    nextHour.setSeconds(0)
    // nextHour.setMilliseconds(0)
    log.debug(`hourly task first run: ${nextHour.getTime() - now.getTime()}`)
    pid = setInterval(task, nextHour.getTime() - now.getTime())
  },
  injectScriptToHead (src, cb) {
    if (Array.isArray(src)) {
      if (src.length > 0) {
        this.injectScriptToHead(src[0], () => {
          src.shift()
          cb()
          this.injectScriptToHead(src, cb)
        })
      }
    } else {
      if (!isScriptLoaded(src)) {
        log.debug('load script: ' + src)
        var head = document.getElementsByTagName('head')[0]
        var script = document.createElement('script')
        script.type = 'text/javascript'
        script.onload = function () {
          if (cb) {
            cb()
          }
        }
        script.src = src
        head.appendChild(script)
      } else {
        log.debug('script already loaded')
        if (cb) {
          cb()
        }
      }
    }
  },
  injectStyleToHead(src, cb) {
    if (Array.isArray(src)) {
      if (src.length > 0) {
        this.injectScriptToHead(src[0], () => {
          src.shift()
          cb()
          this.injectScriptToHead(src, cb)
        })
      }
    } else {
      if (!isStyleLoaded(src)) {
        log.debug('load style: ' + src)
        var head = document.getElementsByTagName('head')[0]
        var script = document.createElement('link')
        script.rel = 'stylesheet'
        script.onload = function () {
          if (cb) {
            cb()
          }
        }
        script.href = src
        head.appendChild(script)
      } else {
        log.debug('style already loaded')
        if (cb) {
          cb()
        }
      }
    }
  },
  bindPartnerCode(body) {
    let links = body.getElementsByTagName('a')

    // get widget type from script tag
    let script = document.getElementById('aw')
    let scriptURL = script.src.split('/')
    let scriptType = scriptURL[scriptURL.length - 1].replace('.js', '')

    let trackingCode = `utm_source=partner_widget&utm_medium=oap_widget&utm_campaign=oap_${scriptType}&partner=${store.config.pCode}`

    if (store.utmLink !== null && store.utmLink !== undefined && store.utmLink !== '') {
      trackingCode = `${store.utmLink}&partner=${store.config.pCode}`
    }

    for (let i = 0; i < links.length; i++) {
      let el = links[i]
      if (el.search) {
        el.href = el.href + '&' + trackingCode
      } else {
        el.href = el.href + '?' + trackingCode
      }
      log.debug(el.href)
    }
  },
  getRandomInt(min, max) {
    min = Math.ceil(min)
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min + 1)) + min
  },
  roundTempeture() {
    return function (text, render) {
      let temp = parseFloat(render(text))
      return Math.round(temp)
    }
  },
  setTempUnit(unit) {
    this.tempUnit = unit;
    document.dispatchEvent(event);
  },
  getTempUnit() {
    if (this.hasOwnProperty('tempUnit')) {
      return this.tempUnit
    }
    return store.config.metric;
  },
  setLocation(location) {
    api.locationKeyLookup(location.Key, (data, err) => {
      this.location = {
        key: data.Key,
        name: data.LocalizedName || data.EnglishName,
        admin: data.AdministrativeArea.LocalizedName,
        country: data.Country.LocalizedName,
        countryId: data.Country.ID,
        gmtOffset: data.TimeZone.GmtOffset
      };
      document.dispatchEvent(locationEvent);
    });
  },
  getLang() {
    if (this.hasOwnProperty('lang')) {
      return this.lang
    }
    return store.config.lang
    }
}

function getTimeshiftTime (hour, minute, second) {
  minute = minute || 0
  second = second || 0
  let now = new Date()
  let day = 86400000 // number of milliseconds in 24 hours

  // get specific time in milliseconds
  let ms = new Date(now.getFullYear(), now.getMonth(), now.getDate(), hour, minute, second, 0)
  let msDiff = ms - now
  if (msDiff < 0) { // its after specific time
    log.debug(`Past set time, using tomorrows time for ${hour}:${minute}:${second}`)
    // use tomorrows time
    msDiff = msDiff + day
  }
  return msDiff
}

function isScriptLoaded(url) {
    if (!url) {
      return false
    }
    var scripts = document.getElementsByTagName('script')
    for (var i = scripts.length; i--;) {
      if (scripts[i].src.indexOf(url) >= 0) return true
    }
    return false
}

function isStyleLoaded(url) {
    if (!url) {
      return false
    }
    var scripts = document.getElementsByTagName('link')
    for (var i = scripts.length; i--;) {
      if (scripts[i].href.indexOf(url) >= 0) return true
    }
    return false
}

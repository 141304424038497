/* global API_ENDPOINT:true, API_VERSION:true, JW_PLAYLIST_ID:true */

import axios from 'axios'
import store from './store'
import log from './log'

function urlBuilder (type, slug1, slug2, query) {
  query = query || null

  let queryString = ''
  if (Array.isArray(query)) {
    query = query.filter(x => x) // remove nulls
    queryString = '&' + query.join('&')
  } else {
    queryString = '&' + query
  }

  if (store.getLang()) {
    queryString += `&language=${store.getLang()}`
  }

  if (queryString.indexOf('&&') >= 0) {
    queryString = queryString.replace('&&', '&')
  }

  return `${API_ENDPOINT}/${type}/${slug1}/${API_VERSION}/${slug2}.json?pcode=${store.config.pCode}&key=${store.config.key}&widget=${store.widgetType}${(queryString || '')}`
}

function fetch (url, single, cb) {
  single = single || false
  if (typeof single === 'function') {
    cb = single
  }

  if (typeof cb !== 'function') {
    log.error('Missing callback')
    return null
  }

  axios.get(url)
    .then(resp => {
      if (resp.data.hasOwnProperty('language')) {
        if (resp.data.language && !store.lang) {
          store.lang = resp.data.language
        }
      }
      if (resp.data.hasOwnProperty('utmlink')) {
        store.utmLink = resp.data.utmlink
      }
      if (resp.data.hasOwnProperty('translations') && resp.data.translations) {
        store.translations = resp.data.translations
      }
      if (single) {
        if (resp.data.length > 1) {
          cb(resp.data[0]) // TODO: ... // QUESTION: What to do here?
        } else if (resp.data.length === 1) {
          cb(resp.data[0])
        } else {
          log.error('failed to find data')
          throw new Error('failed to find data')
        }
      } else {
        cb(resp.data.data)
      }
    })
    .catch(err => {
      log.error(err)
      if (err.hasOwnProperty('response')) {
        switch (err.response.status) {
          case 401:
            store.rootEl.innerHTML = '<div>Unauthorized</div>'
            break
        }
        cb(null, err)
      }
    })
}

function search (q, cb, firstLoad) {
  let url = urlBuilder('list', 'locations', 'search', [
    q ? `q=${q}` : null,
    firstLoad ? 'first=true' : null,
    store.config.location ? `location=${store.config.location}` : null,
    store.config.location && store.config.isAccuWeatherLocationId != null ? `isAccuWeatherLocationId=${store.config.isAccuWeatherLocationId}` : null
  ])
  fetch(url, false, (data, err) => {
    if (err) {
      log.error(err)
      return null
    }
    cb(data, err)
  })
}

function location (q, cb, firstLoad) {
  firstLoad = firstLoad || null
  search(q, (data, err) => {
    let topMatch = data
    if (Array.isArray(data)) {
      topMatch = data[0]
    }
    store.location = {
      name: topMatch.LocalizedName || topMatch.localizedName || topMatch.EnglishName,
      admin: topMatch.AdministrativeArea.LocalizedName,
      country: topMatch.Country.LocalizedName,
      countryId: topMatch.Country.ID,
      key: topMatch.Key || topMatch.key,
      geoPosition: {
        latitude: topMatch.GeoPosition.Latitude,
        longitude: topMatch.GeoPosition.Longitude
      },
      gmtOffset: topMatch.TimeZone.GmtOffset
    }
    cb(topMatch, err)
  }, firstLoad)
}

function locationKeyLookup (key, cb) {
  let url = urlBuilder('object', 'locations', key)
  fetch(url, false, (data, err) => {
    store.location = {
      name: data.LocalizedName || data.localizedName,
      key: data.Key || data.key,
      geoPosition: {
        latitude: data.GeoPosition.Latitude,
        longitude: data.GeoPosition.Longitude
      },
      gmtOffset: data.TimeZone.GmtOffset
    }
    cb(data, err)
  })
}

// to be removed...
function localWeather (cb) {
  if (!store.location.key) {
    log.error('LOCATION NOT SET')
    return null
  }

  log.warn('THIS METHOD IS TO BE REMOVED')

  let url = urlBuilder('object', 'localweather', store.location.key, [(store.config.metric.toLowerCase() === 'c' ? 'metric=true' : 'metric=false'), 'getPhotos=true'])
  fetch(url, false, cb)
}

function currentConditions (cb) {
  if (!store.location.key) {
    log.error('LOCATION NOT SET')
    return null
  }

  let url = urlBuilder('list', 'currentconditions', store.location.key, ['getphotos=true'])
  fetch(url, false, cb)
}

function fiveDayForecast (cb) {
  let url = urlBuilder('object', 'forecasts', `daily/5day/${store.location.key}`, [(store.config.metric.toLowerCase() === 'c' ? 'metric=true' : 'metric=false')])
  fetch(url, false, cb)
}

function alerts (cb) {
  if (!store.location.key) {
    log.error('LOCATION NOT SET')
    return null
  }

  let url = urlBuilder('list', 'alerts', `${store.location.key}`, ['details=true'])
  fetch(url, false, cb)
}

function locationAutocomplete (q, cb) {
  let url = urlBuilder('list', 'locations', 'cities/autocomplete', [`q=${q}`, `alias=always`])
  fetch(url, false, (data, err) => {
    if (err) {
      log.error(err)
      return null
    }
    cb(data, err)
  })
}

function imagery (cb) {
  if (!store.location.key) {
    log.error('LOCATION NOT SET')
    return null
  }

  let size = '640x480'
  let url = urlBuilder('object', 'imagery', `maps/radsat/${size}/${store.location.key}`)
  fetch(url, false, cb)
}

function minuteCast (cb) {
  let lat = store.location.geoPosition.latitude
  let long = store.location.geoPosition.longitude
  let url = urlBuilder('object', 'forecasts', 'minute', [`q=${lat},${long}`])
  fetch(url, false, cb)
}

function hourlyForecast (cb) {
  if (store.location.key) {
    let url = urlBuilder('list', 'forecasts', `hourly/12hour/${store.location.key}`, ['details=true', (store.config.metric.toLowerCase() === 'c' ? 'metric=true' : 'metric=false')])
    fetch(url, false, cb)
  } else {
    log.error('Missing location key')
  }
}

function hourly (day, cb) {
  if (store.location.key) {
    let url = `${API_ENDPOINT}/hourly/${store.location.key}?pcode=${store.config.pCode}&key=${store.config.key}&widget=${store.widgetType}&${'metric=' + store.getTempUnit()}`
    url = url + '&gmt=' + store.location.gmtOffset
    if (store.getLang()) {
      url = url + '&language=' + store.getLang()
    }
    if (day) {
      url = url + '&day=' + day
    }
    fetch(url, false, cb)
  } else {
    log.error('Missing location key')
  }
}

function daily (cb) {
  if (store.location.key) {
    let url = `${API_ENDPOINT}/daily/${store.location.key}?pcode=${store.config.pCode}&key=${store.config.key}&widget=${store.widgetType}&${'metric=' + store.getTempUnit()}`
    url = url + '&gmt=' + store.location.gmtOffset
    if (store.getLang()) {
      url = url + '&language=' + store.getLang()
    }
    fetch(url, false, cb)
  } else {
    log.error('Missing location key')
  }
}

function current (cb) {
  if (store.location.key) {
    let url = `${API_ENDPOINT}/current/${store.location.key}?pcode=${store.config.pCode}&key=${store.config.key}&widget=${store.widgetType}&${'metric=' + store.getTempUnit()}`
    url = url + '&gmt=' + store.location.gmtOffset
    if (store.getLang()) {
      url = url + '&language=' + store.getLang()
    }
    fetch(url, false, cb)
  } else {
    log.error('Missing location key')
  }
}

function getJwplayerPlaylist (cb) {
  let url = `https://cdn.jwplayer.com/v2/playlists/${JW_PLAYLIST_ID}`

  axios.get(url)
    .then(resp => {
      cb(resp.data)
    })
    .catch(err => {
      log.error(err)
      cb(null, err)
    })
}

export default {
  location,
  search,
  localWeather,
  alerts,
  locationAutocomplete,
  currentConditions,
  fiveDayForecast,
  imagery,
  getJwplayerPlaylist,
  minuteCast,
  hourlyForecast,
  hourly,
  current,
  daily,
  locationKeyLookup
}

const defaultTag = 'AWW'

export default {
  log (message, tag) {
    tag = tag || defaultTag
    console.log(`${tag}: ${message}`)
  },
  debug (message, tag) {
    tag = tag || defaultTag
    console.debug(`${tag}: ${message}`)
  },
  error (message, tag) {
    tag = tag || defaultTag
    console.error(`${tag}: ${message}`)
  },
  warn (message, tag) {
    tag = tag || defaultTag
    console.warn(`${tag}: ${message}`)
  }
}

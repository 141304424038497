/* globals DEFAULT_ALERT_INTERVAL:true, DEFAULT_WEATHER_INTERVAL:true,
           DEFAULT_THEME:true, DEFAULT_METRIC:true, DEFAULT_LINK_TARGET:true */

import api from './api'
import style from './styles/main.css'
import store from './store'
import log from './log'

function init (widgetType, window, inject, func) {
  log.debug('Weather Widget Starting...')

  // Stop from loading on IE
  if (isIE(window)) {
    log.warn('Internet Explorer is not a supported browser for the AccuWeather Weather Widget. Please use a different browser.')
    return null
  }

  let config = initConfig(widgetType)

  // 7PM timeshift // 19 is 7PM
  store.timeShift(19, 0, 0, store.fetchAndInjectWeather.bind(null, inject, null))

  // 12AM timeshift
  store.timeShift(0, 0, 0, store.fetchAndInjectWeather.bind(null, inject, null))

  // get location
  api.location(null, (location, err) => {
    if (err) {
      log.error('Failed to find location')
    }
    store.fetchAndInjectWeather(inject, () => {
      // finished reneder event hook
      if (config.events && config.events.finishedForecast) {
        config.events.finishedForecast(func)
      }
    })
  }, true)
}

// https://stackoverflow.com/a/7356528
function isFunction (functionToCheck) {
  return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]'
}

function setConfig (oldConfig, newConfig) {
  let returnConfig = Object.assign({}, oldConfig)
  for (let key in newConfig) {
    if (oldConfig.hasOwnProperty(key)) {
      let oldValue = oldConfig[key]
      let newValue = newConfig[key]

      if (oldValue === newValue) { // NOTE: not sure if this check is needed
        log.debug('config value already set')
      } else {
        if (key === 'events') { // check if events are functions...
          returnConfig[key] = {}
          for (let event in newConfig[key]) {
            let eventFunc = newConfig[key][event]
            if (isFunction(eventFunc)) {
              returnConfig[key][event] = eventFunc
            } else {
              log.warn(`SKIPPING EVENT: '${event}' is not a function`)
            }
          }
        } else {
          returnConfig[key] = newValue
        }
      }
    } else {
      log.warn(`${key} is not a value config property`)
    }
  }
  return returnConfig
}

function isIE (window) {
  var ua = window.navigator.userAgent
  var msie = ua.indexOf('MSIE ')

  return msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./)
}

function initConfig (widgetType) {
  let config = {
    idTag: null,
    pCode: null,
    key: null,
    location: null,
    isAccuWeatherLocationId: null,
    lang: null,
    theme: null,
    linkTarget: null,
    metric: null,
    weatherInterval: null, // number of minutes
    alertInterval: null, // number of minutes
    variant: 1,
    events: {
      finishedForecast: null,
      finishedHourly: null,
      finishedMinuteCast: null,
      finishedAlerts: null,
      finishedBackgroundImage: null
    },
    disableResponsivenness: null // (needed for PDF creation)
  }

  store.widgetType = widgetType

  // go though api queue
  let globalObject = window[window['Accu-Widget']]
  let queue = globalObject.q
  if (queue) {
    for (let i = 0; i < queue.length; i++) {
      let key = queue[i][0].toLowerCase()
      let value = queue[i][1]
      if (key === 'config') {
        config = setConfig(config, value)
      }
    }
  } else {
    log.warn('queue is empty')
  }

  // config check
  if (!config.idTag) {
    log.error('idTag is not set')
    return null
  }
  if (!config.pCode) {
    log.error('Missing pCode')
    return null
  }

  // if (!config.location) {
  //   log.error('location not set')
  //   return null
  // }
  if (!config.theme || ['light', 'dark'].indexOf(config.theme) < 0) {
    log.debug('Theme not set; Using default')
    config.theme = DEFAULT_THEME
  }
  if (!config.metric || ['F', 'C'].indexOf(config.metric) < 0) {
    log.debug('Metric not set; Using default')
    config.metric = DEFAULT_METRIC
  }
  if (!config.linkTarget) {
    log.debug('Link Target not set; Using default')
    config.linkTarget = DEFAULT_LINK_TARGET
  }
  if (!config.weatherInterval) {
    log.debug('Weather Interval not set; Using default')
    config.weatherInterval = DEFAULT_WEATHER_INTERVAL
  }
  if (!config.alertInterval) {
    log.debug('Alert Interval not set; Using default')
    config.alertInterval = DEFAULT_ALERT_INTERVAL
  }

  if (!store.rootEl) {
    store.rootEl = document.getElementById(config.idTag)
  }

  if (!store.rootEl) {
    log.error('failed to bind to HTML element')
  }

  // bind main styles
  store.rootEl.classList.add(style['aw-widget-current'])

  // set store
  store.config = config
  store.widgetType = widgetType + '@' + store.config.variant
  Object.freeze(store.config) // stop changes to config
  store.activeClass = style.active

  // loader
  store.rootEl.innerHTML = '<div>Loading...</div>' // this will be displayed while data is being fetched

  return config
}

export {
  init,
  initConfig,
  isIE
}

import api from '../../api'
import log from '../../log'
import style from '../../styles/main.css'
import AwesompleteStyle from '../../styles/vendors/awesomplete.css'
import store from '../../store'
import { addClass } from '../../utilities'
import Awesomplete from '../../libs/awesomplete'

function bindSearch (inject, onSubmit) {
  onSubmit = onSubmit || null

  // search container
  let elSearch = document.getElementById(style['aw-search'])
  elSearch.onclick = initSearchInput

  // search icon
  let elSearchIcon = document.getElementById('aw-search-icon')

  // search text
  let elSearchText = document.getElementById(style['aw-search-text'])

  // search input
  let elSearchInput = document.createElement('input')
  elSearchInput.placeholder = elSearch.dataset.searchText
  elSearchInput.autofocus = 'autofocus'
  addClass(elSearchInput, style['search-input'])
  elSearchInput.autocomplete = false

  let searchAutoComplete = null

  elSearchInput.onkeyup = function (e) {
    let asciiKey = null
    var pressedKey = e.which || e.keyCode || 0 // https://stackoverflow.com/a/4471635

    // enter button to submit new location
    if (pressedKey === 13 && searchAutoComplete.listCount === 0) { // enter
      if (onSubmit) {
        onSubmit()
      }
      newLocation(inject, elSearchInput.value)
      return
    } else if (pressedKey >= 37 && pressedKey <= 40) { // arrow keys
      return
    } else if (pressedKey === 8 && elSearchInput.value.length === 0) { // backspace
      // remove input and place text
      elSearch.replaceChild(elSearchText, document.getElementsByClassName(AwesompleteStyle['awesomplete'])[0])

      // show search icon
      elSearchIcon.style.display = 'inline-block'

      // add onclick again
      elSearch.onclick = initSearchInput

      searchAutoComplete = null
    } else if (pressedKey === 229) { // this is a work around for the android keypress issue https://bugs.chromium.org/p/chromium/issues/detail?id=118639
      let lastKeyPressed = elSearchInput.value[elSearchInput.value.length - 1]
      asciiKey = lastKeyPressed.charCodeAt(0)
    } else {
      asciiKey = e.key.charCodeAt(0)
    }

    // ajax to get autocomplete list
    if (asciiKey <= 122 && asciiKey >= 65) { // any letter
      api.locationAutocomplete(elSearchInput.value, (data) => {
        if (data && data.length && searchAutoComplete) {
          searchAutoComplete.list = data.map(d => {
            return {
              label: `${d.LocalizedName}, ${d.AdministrativeArea.ID}, ${d.Country.ID}`,
              value: d.Key
            }
          })
        } else {
          if (searchAutoComplete) {
            searchAutoComplete.list = []
          }
        }
      })
    }
  }

  function initSearchInput () {
    // clear existing text
    elSearchInput.value = ''

    // hide search icon
    elSearchIcon.style.display = 'none'

    // replace text with input
    elSearch.replaceChild(elSearchInput, elSearchText)

    // replace click event from search container
    elSearch.onclick = null

    // focus on input
    elSearchInput.focus()
    // eslint-disable-next-line no-new
    searchAutoComplete = new Awesomplete('.' + style['search-input'], {
      list: [],
      minChars: 2,
      maxItems: 5,
      autoFirst: false,
      mainClass: AwesompleteStyle['awesomplete'],
      data: function (item, input) {
        return item
      },
      filter: Awesomplete.FILTER_STARTSWITH,
      sort: function (a, b) {} // need so sort from API is used
    })

    elSearchInput.addEventListener('awesomplete-selectcomplete', function (event) {
      newLocation(inject, event.text.value, true, {
        name: event.text.label,
        key: event.text.value
      })
    })
  }

  function newLocation (injectLoc, q, skipSearch, selectedLocation) {
    skipSearch = skipSearch || false

    log.debug(`new location ${q}`)

    if (skipSearch) {
      store.location = selectedLocation
      api.locationKeyLookup(q, (location, err) => {
        if (err) {
          log.error('Failed to lookup location')
        }
        store.fetchAndInjectWeather(injectLoc)
      })
    } else {
      // get location
      api.location(q, (location, err) => {
        if (err) {
          log.error('Failed to find location')
        }
        store.fetchAndInjectWeather(injectLoc)
      })
    }
  }
}

export {
  bindSearch
}

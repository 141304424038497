/* global IMAGES_ICON_ENDPOINT:true */

import Mustache from 'mustache'
import html1 from './smallBase1.html'
import html2 from './smallBase2.html'
import searchTemplate from '../_search.html'
import alertsTemplate from '../_alerts.html'
import logoTemplate from '../_logo.html'
import weatherVariant1 from './_smallPanel1.html'
import weatherVariant2 from './_smallPanel2.html'
import style from '../../../styles/main.css'
import gridMin from '../../../styles/vendors/grids-min.css'
import gridResp from '../../../styles/vendors/grids-responsive-min.css'
import store from '../../../store'
import { bindSearch } from '../search'
import alerts from '../alerts'
import { mungeWeatherData } from '../forcastPartial'
import _forecastTemplate from '../_forecastPartial.html'
import { searchIcon } from '../../../icons'

const grid = { ...gridMin, ...gridResp }

function create (data, theme, linkTarget) {
  let weatherTemplate = null
  let html = null
  switch (store.config.variant) {
    case 2:
      weatherTemplate = weatherVariant2
      html = html2
      break
    default:
      weatherTemplate = weatherVariant1
      html = html1
  }

  return Mustache.render(html, {
    ...mungeWeatherData(data, 'pure-u-1'),
    styles: style,
    grid: grid,
    linkTarget: linkTarget,
    iconTheme: style['svg-light'],
    searchIcon: searchIcon(),
    searchText: store.translations.search,
    logo: `${IMAGES_ICON_ENDPOINT}/logos/accuweather.svg`
  }, {
    alerts: alertsTemplate,
    logo: logoTemplate,
    search: searchTemplate,
    weather: weatherTemplate,
    forecast: _forecastTemplate
  })
}

function inject (body, data, theme, linkTarget) {
  body.innerHTML = create(data, theme, linkTarget)
  alerts.fetchAndInjectAlerts()
  bindSearch(inject)
}

export default {
  inject,
  create
}

import styles from './styles/main.css'

import { icon } from '@fortawesome/fontawesome-svg-core'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons/faAngleDown'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons/faAngleRight'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons'

const angleDownIcon = icon(faAngleDown).html
const closeIcon = icon(faTimes).html
const playIcon = icon(faPlayCircle).html
const angleRightIcon = icon(faAngleRight).html

const responsivePlayIcon = icon(faPlayCircle, {
  styles: { 'height': '100%', 'width': '100%' }
}).html

const alertIcon = `<svg xmlns="http://www.w3.org/2000/svg"
  class="${styles['icon-alert']}"
  width="16"
  height="16"ß
  viewBox="0 0 16 18">
  <path fill="#FFF" fill-rule="evenodd" d="M8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16zM7 3v7h2V3H7zm1 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
</svg>`

function chevron (width, style) {
  width = width || 10
  style = style || ''
  return `<svg
    class="${styles['icon-chevron-right']}"
    xmlns="http://www.w3.org/2000/svg"
    width="${width}"
    height="100%"
    style="${style}"
    viewBox="0 0 10 6">
    <path d="M10 .969L9.037 0 5 4.063.963 0 0 .969 5 6z" />
  </svg>`
}

function searchIcon (width) {
  width = width || 16
  let height = width

  return `<svg xmlns="http://www.w3.org/2000/svg"
    class="${styles['icon-search']}"
    width="${width}"
    height="${height}"
    viewBox="0 0 17 18">
    <path d="M15.784 13.68l-4.096-4.096c.585-.955.94-2.067.94-3.27a6.315 6.315 0 1 0-6.314 6.314 6.253 6.253 0 0 0 3.27-.937l4.096 4.094a.751.751 0 0 0 1.052 0l1.052-1.052a.75.75 0 0 0 0-1.053zm-9.47-3.157a4.21 4.21 0 1 1 0-8.419 4.21 4.21 0 0 1 0 8.42z" />
  </svg>`
}

export {
  searchIcon,
  angleDownIcon,
  closeIcon,
  playIcon,
  responsivePlayIcon,
  angleRightIcon,
  alertIcon,
  chevron
}

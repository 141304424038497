function hasClass (el, className) {
  if (el.classList) { return el.classList.contains(className) } else {
    var currentClassName = el.getAttribute('class')
    if (currentClassName.length > 0) {
      if (currentClassName.indexOf(className) >= 0) {
        return true
      }
    }
  }
  return false
}

function addClass (el, className) {
  if (el.classList) { el.classList.add(className) } else {
    var currentClassName = el.getAttribute('class')
    if (typeof currentClassName !== 'undefined' && currentClassName) {
      el.setAttribute('class', currentClassName + ' ' + className)
    } else {
      el.setAttribute('class', className)
    }
  }
}

function removeClass (el, className) {
  if (el.classList) { el.classList.remove(className) } else {
    var currentClassName = el.getAttribute('class')
    if (typeof currentClassName !== 'undefined' && currentClassName) {
      var class2RemoveIndex = currentClassName.indexOf(className)
      if (class2RemoveIndex > -1) {
        var class2Remove = currentClassName.substr(class2RemoveIndex, className.length)
        var updatedClassName = currentClassName.replace(class2Remove, '').trim()
        el.setAttribute('class', updatedClassName)
      }
    } else {
      el.removeAttribute('class')
    }
  }
}

export {
  hasClass,
  addClass,
  removeClass
}
